import dayjs from "dayjs";

const JW23 = () => {
  // if (dayjs().isAfter(dayjs("2023-12-18"))) {
  //   window.location.replace("https://youtu.be/HWtZOa8zge0");
  // }

  return (
    <div className="App">
      <header className="App-header">
        {!dayjs().isAfter(dayjs("2023-12-24")) && (
          <>
            <img src="/dog1.png" alt="hund1" style={{ height: "200px" }} />
            <p>Du Lümmel bist zu früh dran</p>
            <img src="/dog2.jpg" alt="hund2" style={{ height: "200px" }} />
          </>
        )}
        {dayjs().isAfter(dayjs("2023-12-24")) && (
          <>
            <iframe
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
              src="https://www.youtube.com/embed/HWtZOa8zge0?si=rXJqdFWEn5qmjJ4y?autoplay=1&vq=hd1080"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay;  encrypted-media; gyroscope"
              allowfullscreen
            ></iframe>
          </>
        )}
      </header>
    </div>
  );
};

export default JW23;
