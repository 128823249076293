const Geb24 = () => {
  let audio = new Audio("/stardew.mp3");

  const start = () => {
    audio.play();
  };

  return (
    <div className="App-header" style={{ width: "100vw", height: "100vh" }}>
      <button
        style={{
          padding: "14px 20px 14px 20px",
          backgroundColor: "orangered",
          border: "none",
          borderRadius: "10px",
          color: "white",
          fontSize: "17px",
          fontWeight: "bold",
        }}
        onClick={() => start()}
      >
        Click me
      </button>
    </div>
  );
};

export default Geb24;
